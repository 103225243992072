import React, { useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore from "swiper";
import { Pagination, Autoplay } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";

import useScrollFadeIn from "../hooks/useScrollFadeIn";
import {
  FiCode,
  FiServer,
  FiLayers,
  FiGithub,
  FiExternalLink,
} from "react-icons/fi";
import "../styles/style.css";

// ✅ 注册 Swiper 模块
SwiperCore.use([Pagination, Autoplay]);

const projectsData = [
  {
    id: "ai",
    name: "AI Projects",
    icon: <FiLayers className="category-icon" />,
    color: "#6366f1",
    items: [
      {
        title: "AI-powered Sentiment Visualizer",
        tags: ["NLP", "Data Visualization", "Deep Learning"],
        description:
          "Transformer-based sentiment analysis system with attention heatmap visualization",
        links: [
          { type: "github", url: "https://github.com/dev-helia" },
          { type: "demo", url: "#" },
        ],
        status: "WIP",
      },
      {
        title: "AI Travel Planning Agent",
        tags: ["LLM", "Recommendation Systems", "Streamlit"],
        description:
          "Automated itinerary generator integrating flight/hotel planning and AI recommendations",
        links: [
          { type: "github", url: "https://github.com/dev-helia" },
          { type: "demo", url: "#" },
        ],
        status: "WIP",
      },
    ],
  },
  {
    id: "web",
    name: "Web Projects",
    icon: <FiCode className="category-icon" />,
    color: "#10b981",
    items: [
      {
        title: "Tech Blog Platform",
        tags: ["Hugo", "Markdown", "GitHub Pages"],
        description:
          "Static blog with full-text search and category organization",
        links: [
          {
            type: "website",
            url: "https://dev-helia.github.io/Tech-Blog-Website/",
          },
          {
            type: "github",
            url: "https://github.com/dev-helia/Tech-Blog-Website",
          },
        ],
      },
    ],
  },
  {
    id: "fullstack",
    name: "Fullstack Projects",
    icon: <FiServer className="category-icon" />,
    color: "#f59e0b",
    items: [
      {
        title: "Promptllery",
        tags: ["React", "Supabase", "Tailwind"],
        description:
          "Prompt management platform with version control and real-time testing",
        links: [
          { type: "github", url: "https://github.com/dev-helia/promptllery" },
          { type: "website", url: "https://promptllery.vercel.app/" },
          {
            type: "demo",
            url: "https://youtu.be/JtV62_bn-bA?si=uVizDVEGGnw3ApqU",
          },
        ],
        images: [
          "/assets/promptllery/1.png",
          "/assets/promptllery/2.png",
          "/assets/promptllery/3.png",
        ],
      },
    ],
  },
];

const ProjectCard = ({ project, color, category }) => {
  return (
    <div
      className="project-card"
      style={{ borderTop: `4px solid ${color}` }}
      data-category={category}
    >
      <div className="card-header">
        <h3>
          {project.title}
          {project.status && (
            <span className="status-badge">{project.status}</span>
          )}
        </h3>
        <div className="tag-group">
          {project.tags.map((tag) => (
            <span key={tag} className="tag">
              {tag}
            </span>
          ))}
        </div>
      </div>

      <p className="description">{project.description}</p>

      {/* 🧡 Swiper 图像轮播区 */}
      {project.images && (
        <div className="project-swiper">
          <Swiper
            pagination={{ clickable: true }}
            autoplay={{ delay: 4000, disableOnInteraction: false }}
            spaceBetween={20}
            loop={true}
            grabCursor={true}
          >
            {project.images.map((img, index) => (
              <SwiperSlide key={index}>
                <div className="swiper-media-container">
                  <img
                    src={img}
                    alt={`${project.title} screenshot ${index + 1}`}
                    className="swiper-media"
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}

      <div className="action-buttons">
        {project.links.map((link) => (
          <a
            key={link.type}
            href={link.url}
            className="project-link"
            target="_blank"
            rel="noopener noreferrer"
            style={{
              backgroundColor: color,
              "--hover-bg": `${color}cc`,
            }}
          >
            {link.type === "github" ? <FiGithub /> : <FiExternalLink />}
            <span>{link.type.toUpperCase()}</span>
          </a>
        ))}
      </div>
    </div>
  );
};

const Projects = () => {
  const [activeTab, setActiveTab] = useState("ai");
  const fadeIn = useScrollFadeIn();

  return (
    <section id="projects" className="projects-section" {...fadeIn}>
      <h2 className="section-title">🚀 Projects</h2>

      <div className="projects-container">
        <nav className="category-nav">
          {projectsData.map((category) => (
            <button
              key={category.id}
              className={`nav-item ${
                activeTab === category.id ? "active" : ""
              }`}
              onClick={() => setActiveTab(category.id)}
              style={{
                color: activeTab === category.id ? category.color : "#666",
                borderLeft: `3px solid ${
                  activeTab === category.id ? category.color : "transparent"
                }`,
              }}
            >
              {category.icon}
              {category.name}
            </button>
          ))}
        </nav>

        <div className="projects-grid">
          {projectsData
            .find((c) => c.id === activeTab)
            .items.map((project) => (
              <ProjectCard
                key={project.title}
                project={project}
                color={projectsData.find((c) => c.id === activeTab).color}
                category={activeTab}
              />
            ))}
        </div>
      </div>
    </section>
  );
};

export default Projects;
