// /hooks/useScrollFadeIn.js
import { useRef, useEffect, useState } from "react";

const useScrollFadeIn = () => {
  const ref = useRef(null);
  const [style, setStyle] = useState({
    opacity: 0,
    transform: "translateY(30px)",
    transition: "opacity 0.6s ease, transform 0.6s ease",
  });

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setStyle({
            opacity: 1,
            transform: "none",
            transition: "opacity 0.6s ease, transform 0.6s ease",
          });
          observer.unobserve(ref.current); // 触发一次就停
        }
      },
      { threshold: 0.2 }
    );

    if (ref.current) observer.observe(ref.current);

    return () => observer.disconnect();
  }, []);

  return { ref, style };
};

export default useScrollFadeIn;
