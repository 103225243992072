import React, { useState, useEffect } from "react"; // 加上 useState 和 useEffect

import Navbar from "./components/Navbar";
import Intro from "./components/Intro";
import About from "./components/About";

import Skills from "./components/Skills";
import Projects from "./components/Projects";
import Contact from "./components/Contact";
// import ScrollTopButton from "./components/ScrollTopButton";

import "./styles/style.css";

function App() {
  const [theme, setTheme] = useState("light"); // 初始为 light 模式

  // 当 theme 变化时，切换 body 的 class
  useEffect(() => {
    document.body.className = theme; // 应用到 <body> 标签上
  }, [theme]);

  const toggleTheme = () => {
    setTheme((prev) => (prev === "light" ? "dark" : "light"));
  };

  return (
    <>
      <Navbar theme={theme} toggleTheme={toggleTheme} />
      <div className="container">
        <Intro />

        <About />
        <Skills />
        <Projects />
        <Contact />
      </div>

      {/* <ScrollTopButton /> */}
    </>
  );
}

export default App;
