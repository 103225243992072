import React, { useState, useRef } from "react";
import useScrollFadeIn from "../hooks/useScrollFadeIn";
import ReCAPTCHA from "react-google-recaptcha";

const Contact = () => {
  const contactAnimation = useScrollFadeIn();
  const recaptchaRef = useRef(null);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [status, setStatus] = useState({
    loading: false,
    success: false,
    error: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus({ loading: true, success: false, error: "" });

    try {
      if (!recaptchaRef.current) {
        throw new Error("reCAPTCHA not ready yet. Please wait and try again.");
      }

      const captcha = await recaptchaRef.current.executeAsync();
      recaptchaRef.current.reset();

      const response = await fetch("http://localhost:5050/send-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ ...formData, captcha }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Something went wrong.");
      }

      setStatus({ loading: false, success: true, error: "" });
      setFormData({ name: "", email: "", message: "" });

      setTimeout(() => {
        setStatus((prev) => ({ ...prev, success: false }));
      }, 3000);
    } catch (err) {
      console.error("🔥 Submit Error:", err);
      setStatus({
        loading: false,
        success: false,
        error: err.message || "Submission failed.",
      });
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  return (
    <section className="section" id="contact" {...contactAnimation}>
      <h2>📩 Contact Me</h2>
      <div className="contact-browser">
        <div className="browser-header">
          <div className="browser-buttons">
            <span className="red"></span>
            <span className="yellow"></span>
            <span className="green"></span>
          </div>
        </div>
        <div className="browser-content">
          <form className="contact-form" onSubmit={handleSubmit}>
            <label htmlFor="name">Name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />

            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />

            <label htmlFor="message">Message</label>
            <textarea
              name="message"
              value={formData.message}
              onChange={handleChange}
              rows="5"
              required
            ></textarea>

            {/* ✅ Invisible reCAPTCHA */}
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              size="invisible"
              ref={recaptchaRef}
            />

            <button type="submit" disabled={status.loading}>
              {status.loading ? (
                <>
                  <i className="fas fa-spinner fa-spin"></i> Sending...
                </>
              ) : (
                "Send"
              )}
            </button>

            {status.success && (
              <div className="success-message">
                <i className="fas fa-check-circle"></i> Message sent
                successfully!
              </div>
            )}
            {status.error && (
              <div className="error-message">
                <i className="fas fa-exclamation-circle"></i> {status.error}
              </div>
            )}
          </form>
        </div>
      </div>
    </section>
  );
};

export default Contact;
